<template>
  <div class="all-box">
    <div class="box">
      <div class="box-left">
        <div class="title">{{ detailList.title }}</div>
        <div class="detail">
          <div class="time">{{ detailList.createTime }}</div>
          <div class="line"></div>
          <div class="author">{{ detailList.author }}</div>
          <div class="line"></div>
          <div class="source">{{ detailList.source }}</div>
        </div>
        <div v-html="detailList.body" class="content"></div>
        <div
          v-if="detailList.attachmentInfo && detailList.attachmentInfo.length"
          class="fujian"
        >
          <div class="title1">附件：</div>
          <div class="item1">
            <el-link
              v-for="(item, i) in detailList.attachmentInfo"
              :key="i"
              type="primary"
              :href="item.linkUrl"
              class="link"
              :underline="false"
              >{{ item.originalFileName }}</el-link
            >
          </div>
        </div>
      </div>
      <!-- <div class="box-right">
                <div class="title">热点资讯</div>
                <div @click="check(item)" v-for="item in this.rightList" :key="item.id" class="content">
                    <div class="name">{{ item.name }}</div>
                    <div class="time">{{ '发布时间：' + item.createTime }}</div>
                </div>
            </div> -->
    </div>
    <PageFooter />
  </div>
</template>
<script>
import { getPortalDetail } from "@/service/ArtsInformation";
import { getHomePortal } from "@/service/home";
import { formatRichText } from "@/utils/formatRichText";
export default {
  data() {
    return {
      list: [],
      id: this,
      detailList: {},
      rightList: [],
    };
  },
  created() {
    this.pageFilter(this.$route.query.page);
    this.getList(this.$route.query.id);
    this.getRight();
  },
  mounted() {
    const isFirstVisit = sessionStorage.getItem("isFirstVisit_B") === null;

    if (isFirstVisit) {
      // 设置标记，表示B页面已经访问过一次
      sessionStorage.setItem("isFirstVisit_B", "false");

      // 执行刷新操作
      window.location.reload();
    }
  },
  methods: {
    check(item) {
      this.pageFilter(1);
      this.getList(item.targetId);
    },
    async getRight() {
      const res = await getHomePortal();
      this.rightList = res.data.referralRight;
    },
    async getList(id) {
      const res = await getPortalDetail(id);
      this.detailList = res.data;
      this.detailList.body = formatRichText(res.data.body);
      if (this.detailList.attachmentInfo) {
        this.detailList.attachmentInfo = JSON.parse(
          this.detailList.attachmentInfo
        );
      } else {
        this.detailList.attachmentInfo = [];
      }
    },
    pageFilter(page) {
      if (page == 1) {
        this.list = ["人艺资讯", "剧院动态", "剧院动态详情"];
        return;
      } else if (page == 2) {
        this.list = ["人艺资讯", "剧院公告", "剧院公告详情"];
        return;
      } else if (page == 3) {
        this.list = ["人艺资讯", "剧目推介", "剧目推介详情"];
        return;
      } else if (page == 4) {
        this.list = ["人艺资讯", "人艺活动", "人艺活动详情"];
        return;
      } else {
        this.list = ["资讯", "详情"];
      }
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  margin-top: 0.8rem;

  .box {
    display: flex;

    .box-left {
      margin: 0 0.5rem;
      width: 100%;

      .title {
        font-size: 0.4rem;
        font-weight: 600;
      }

      .detail {
        display: flex;
        height: 0.2667rem;
        line-height: 0.2667rem;
        margin-top: 0.1333rem;
        font-size: 25/75rem;

        .line {
          margin-left: 0.1333rem;
        }

        .author {
          margin-left: 0.1333rem;
        }
      }
    }

    .box-right {
      margin-left: 0.2rem;
      width: 3rem;

      .title {
        font-size: 0.3467rem;
        font-weight: 600;
      }

      .content {
        margin-top: 0.2333rem;
      }
    }
  }
}
.fujian {
  // margin: 0 0.5rem;
  margin-bottom: 20/75rem;
  .title1 {
    font-size: 25/75rem;
  }
  .item1 {
    display: flex;
    flex-direction: column;
    .link {
      text-align: start;
      margin: 5/75rem 0;
    }
  }
}
.el-link {
  display: inline-block;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25/75rem;
}
</style>
<style>
.content img {
  max-width: 100%;
}
</style>
